import React from 'react';
import Card from 'src/components/Shared/Card';
import Table from 'src/components/Shared/Table';
import ParkingDetectionStatus from 'src/constants/Parking/ParkingDetectionStatus';
import type ParkingPlace from 'src/models/Parking/ParkingPlace/ParkingPlace';
import { getDetectionStatusColor } from 'src/utils/getDetectionStatusColor';

interface ParkingPlaceTableProps {
  parkingPlaces: ParkingPlace[];
  detectionStatus: ParkingDetectionStatus | undefined;
  isLoading: boolean;
  openEditModal: (data?: ParkingPlace) => void;
}

const ParkingPlacesTable: React.FC<ParkingPlaceTableProps> = (props) => {
  const { parkingPlaces, detectionStatus, isLoading, openEditModal } = props;
  return (
    <Card>
      <Table isLoading={isLoading}>
        <Table.Head>
          <Table.Row>
            <Table.Header>ID</Table.Header>
            <Table.Header>Name</Table.Header>
            <Table.Header>UUID</Table.Header>
            <Table.Header>Status</Table.Header>
            <Table.Header />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {parkingPlaces.map((item) => {
            const { id, name, uuid, occupancyStatus } = item;
            return (
              <Table.Row key={id}>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell primary action={openEditModal} data={item}>
                  {name}
                </Table.Cell>
                <Table.Cell>{uuid}</Table.Cell>
                <Table.Cell
                  occupancyColor={getDetectionStatusColor(
                    occupancyStatus,
                    detectionStatus === ParkingDetectionStatus.ONLINE
                  )}
                >
                  {occupancyStatus.toUpperCase()}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Card>
  );
};
export default ParkingPlacesTable;

import Color from 'src/constants/Shared/Color';
import styled, { css } from 'styled-components';

const DetectCameraImg = styled.img<{
  addOpacity: boolean;
  modalView?: boolean;
  position?: string;
}>`
  width: 700px;
  align-self: center;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  border-radius: 5px;
  top: 0;
  left: 0;

  @media (min-width: 1401px) {
    margin-right: 20px;
  }

  @media (max-width: 760px) {
    width: 600px;
  }

  @media (max-width: 660px) {
    width: 500px;
  }

  @media (max-width: 560px) {
    width: 400px;
  }

  @media (max-width: 460px) {
    width: 300px;
  }

  ${(props) => {
    const { addOpacity } = props;

    if (addOpacity) {
      return css`
        opacity: 0.3;
      `;
    }

    return css``;
  }}

  ${(props) => {
    const { position } = props;

    if (position) {
      return css`
        position: ${position};
      `;
    }

    return css``;
  }}

	${(props) => {
    const { modalView } = props;

    if (modalView) {
      return css`
        margin: 10px 0;

        @media (max-width: 750px) {
          width: 400px;
        }

        @media (max-width: 600px) {
          width: 300px;
        }
      `;
    }

    return css``;
  }}
`;

const DetectCameraWrapper = styled.div<{ modalView?: boolean }>`
  width: 700px;
  margin: 10px;
  position: relative;
  height: fit-content;

  @media (max-width: 760px) {
    width: 600px;
  }

  @media (max-width: 660px) {
    width: 500px;
  }

  @media (max-width: 560px) {
    width: 400px;
  }

  @media (max-width: 460px) {
    width: 300px;
  }

  ${(props) => {
    const { modalView } = props;

    if (modalView) {
      return css`
        margin: 10px 0;

        @media (max-width: 750px) {
          width: 400px;
        }

        @media (max-width: 600px) {
          width: 300px;
        }
      `;
    }
    return css``;
  }}
`;

const UpdateInfo = styled.div`
  display: flex;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  position: absolute;
  top: 40%;
  color: ${Color.TEXT_DARKER};
  padding: 10px 0;
  z-index: 2;
`;

const StyledSvg = styled.svg`
  position: relative;
  z-index: 1;
`;

export { DetectCameraImg, DetectCameraWrapper, UpdateInfo, StyledSvg };

import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import Button from 'src/components/Shared/Button';
import DeleteButton from 'src/components/Shared/Buttons/DeleteButton';
import Control from 'src/components/Shared/Control';
import {
  DetectCameraImg,
  DetectCameraWrapper,
  UpdateInfo,
  StyledSvg,
} from 'src/components/Shared/DetectCamera';
import Line from 'src/components/Shared/Line';
import { useGlobalCancelModal } from 'src/components/Shared/Modals/GlobalCancelModal';
import SelectField from 'src/components/Shared/SelectField';
import Spinner from 'src/components/Shared/Spinner';
import TextField from 'src/components/Shared/TextField';
import ParkingDetectionStatus from 'src/constants/Parking/ParkingDetectionStatus';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import type CameraParkingPlaces from 'src/models/Parking/DetectCamera/CameraParkingPlaces';
import type DetectCamera from 'src/models/Parking/DetectCamera/DetectCamera';
import type DetectCameraFormValues from 'src/models/Parking/DetectCamera/DetectCameraFormValues';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import type Option from 'src/models/Shared/Option';
import formatDates from 'src/utils/formatDates';
import { getDetectionStatusColor } from 'src/utils/getDetectionStatusColor';
import DetailsColumn from '../DetailsColumn';
import DetailsData from '../DetailsData';
import DetailsRow from '../DetailsRow';

interface DetectCameraUpdateFormProps {
  closeParentModal: () => void;
  details: DetectCamera | undefined;
  gateways: DetectGateway[];
  frame: string;
  lotDetectionStatus: ParkingDetectionStatus | undefined;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  cameraPlaces: CameraParkingPlaces[] | undefined;
}
export default function DetectCameraUpdateForm(
  props: DetectCameraUpdateFormProps
) {
  const {
    details,
    isLoading,
    closeParentModal,
    gateways,
    frame,
    lotDetectionStatus,
    openRemoveModal,
    cameraPlaces,
  } = props;
  const [searchGateway, setSearchGateway] = useState('');
  const { isSubmitting, dirty } = useFormikContext<DetectCameraFormValues>();

  const shouldRenderDetails = !isLoading && details;

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const gatewayOptions: Option[] = useMemo(
    () =>
      gateways
        .filter(({ name }) =>
          name.toLowerCase().includes(searchGateway.toLowerCase())
        )
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [gateways, searchGateway]
  );

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  const isLotOnline = useMemo(
    () => lotDetectionStatus === ParkingDetectionStatus.ONLINE,
    [lotDetectionStatus]
  );

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && details && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='NAME'
              placeholder='Name'
              type='text'
              name='name'
              short
              stacked
            />
            <TextField
              label='UUID'
              placeholder='UUID'
              type='text'
              name='uuid'
              stacked
            />
            <SelectField
              label='GATEWAY'
              placeholder='Select Gateway'
              name='gateway'
              options={gatewayOptions}
              short
              onSearch={setSearchGateway}
              search={searchGateway}
              readonly
              rightAlign
            />
            <DetailsData
              label='Last updated'
              value={formatDates(details.updatedAt)}
              formStyle
            />
            <DetectCameraWrapper modalView>
              {details.viewSchema?.viewBox && (
                <StyledSvg
                  viewBox={details.viewSchema?.viewBox}
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                >
                  {cameraPlaces &&
                    cameraPlaces.map((place) => {
                      const { placeId, occupancyStatus, cx, cy, r } = place;

                      const addOpacity = !isLotOnline ? '0.5' : undefined;

                      return (
                        <g key={placeId}>
                          <circle
                            cx={cx}
                            cy={cy}
                            r={r}
                            fill={
                              occupancyStatus &&
                              getDetectionStatusColor(
                                occupancyStatus,
                                isLotOnline
                              )
                            }
                            fillOpacity='0.5'
                          />
                          <circle
                            cx={cx}
                            cy={cy}
                            r={parseInt(r) - 5}
                            fill={
                              occupancyStatus &&
                              getDetectionStatusColor(
                                occupancyStatus,
                                isLotOnline
                              )
                            }
                            strokeLinejoin='round'
                            strokeWidth='2'
                            strokeLinecap='round'
                            stroke='#000'
                            fillOpacity={addOpacity}
                            strokeOpacity={addOpacity}
                          />
                        </g>
                      );
                    })}
                </StyledSvg>
              )}
              {!isLotOnline && (
                <UpdateInfo>
                  {details.updatedAt
                    ? `Last updated: ${formatDates(details.updatedAt, true)}`
                    : undefined}
                </UpdateInfo>
              )}
              <DetectCameraImg
                src={frame}
                alt='Detect Camera'
                addOpacity={!isLotOnline}
                modalView
                position={details.viewSchema?.viewBox ? 'absolute' : 'relative'}
              />
            </DetectCameraWrapper>
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <DeleteButton onClick={onRemove} />
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}

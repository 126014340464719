import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type Lot from 'src/models/Parking/Lot/Lot';
import ActionIcon from '../../constants/Shared/ActionIcon';
import AppRole from '../../constants/Shared/AppRole';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Control from '../Shared/Control';
import Table from '../Shared/Table';

interface LotTableProps {
  openCreateModal: () => void;
  openRemoveModal: (id: number) => void;
  data: Lot[];
  isLoading?: boolean;
}

export default function LotTable(props: LotTableProps) {
  const { data, openCreateModal, openRemoveModal, isLoading } = props;
  const { push } = useHistory();
  const userHasRole = useUserHasRole();

  const removeItem = useCallback(
    (data?: Lot) => {
      if (!data) {
        return;
      }

      const { id } = data;

      openRemoveModal(id);
    },
    [openRemoveModal]
  );

  const editItem = useCallback(
    (data?: Lot) => {
      if (!data) {
        return;
      }

      const { id } = data;

      push(`/parking/lots/${id}/products`);
    },
    [push]
  );

  const userCanChangeLot = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  return (
    <>
      {userCanChangeLot && (
        <Card>
          <Control>
            <Button size={ButtonSize.MIDDLE} onClick={openCreateModal}>
              + Add New Lot
            </Button>
          </Control>
        </Card>
      )}
      <Card>
        <Table isLoading={isLoading}>
          <Table.Head>
            <Table.Row>
              <Table.Header>PARKING LOT</Table.Header>
              <Table.Header>ADDRESS</Table.Header>
              {userCanChangeLot && <Table.Header />}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((lotFindAllDetails) => {
              const { name, address, id } = lotFindAllDetails;

              return (
                <Table.Row key={id}>
                  <Table.Cell
                    action={editItem}
                    primary
                    data={lotFindAllDetails}
                  >
                    {name}
                  </Table.Cell>
                  <Table.Cell>{address}</Table.Cell>
                  {userCanChangeLot && (
                    <Table.Cell
                      action={removeItem}
                      primary
                      data={lotFindAllDetails}
                    >
                      <Table.Icon icon={ActionIcon.DELETE} alignRight />
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
}

import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  Fragment,
} from 'react';
import DetectCameraList from 'src/components/Parking/Detection/DetectCameraList';
import NoDetectionMessage from 'src/components/Parking/Detection/NoDetectionMessage';
import ParkingPlacesTable from 'src/components/Parking/Detection/ParkingPlacesTable';
import ParkingSummary from 'src/components/Parking/Detection/ParkingSummary';
import ParkingPlaceDetailsModal from 'src/components/Parking/Modals/ParkingPlaceDetailsModal';
import Error from 'src/components/Shared/Error';
import ErrorDiv from 'src/components/Shared/ErrorDiv';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import GlobalModal from 'src/components/Shared/Modals/GlobalModal';
import Pagination from 'src/components/Shared/Pagination';
import Title from 'src/components/Shared/Title';
import GatewayStatus from 'src/constants/Parking/GatewayStatus';
import { ParkingDetectionImplementationStatus } from 'src/constants/Parking/ParkingDetectionImplementationStatus';
import ParkingDetectionStatus from 'src/constants/Parking/ParkingDetectionStatus';
import PaginationSize from 'src/constants/Shared/DataSize';
import PaginationItemDisplay from 'src/constants/Shared/PaginationItemDisplay';
import SocketEvents from 'src/constants/Shared/SocketEvents';
import SocketPath from 'src/constants/Shared/SocketPath';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import useSocket from 'src/hooks/Shared/useSocket';
import toDetectGatewayChange from 'src/mappers/Parking/DetectGateway/toDetectGatewayChange';
import toLotDetectionChangeEvent from 'src/mappers/Parking/Lot/toLotDetectionChangeEvent';
import toParkingPlaceChange from 'src/mappers/Parking/ParkingPlace/toParkingPlaceChange';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import type DetectGatewayChange from 'src/models/Parking/DetectGateway/DetectGatewayChange';
import type DetectGatewayChangePayload from 'src/models/Parking/DetectGateway/DetectGatewayChangePayload';
import type Lot from 'src/models/Parking/Lot/Lot';
import type LotDetectionChangeEvent from 'src/models/Parking/Lot/SocketEvents/LotDetectionChangeEvent/LotDetectionChangeEvent';
import type LotDetectionChangeEventData from 'src/models/Parking/Lot/SocketEvents/LotDetectionChangeEvent/LotDetectionChangeEventData';
import type LotDetectionChangeEventPayload from 'src/models/Parking/Lot/SocketEvents/LotDetectionChangeEvent/LotDetectionChangeEventPayload';
import type ParkingPlace from 'src/models/Parking/ParkingPlace/ParkingPlace';
import type ParkingPlaceChange from 'src/models/Parking/ParkingPlace/ParkingPlaceChange';
import type ParkingPlaceChangePayload from 'src/models/Parking/ParkingPlace/ParkingPlaceChangePayload';
import type Zone from 'src/models/Parking/Zone/Zone';
import type Meta from 'src/models/Shared/Meta';
import useDetectGatewayService from 'src/services/Parking/useDetectGatewayService';
import useParkingPlaceService from 'src/services/Parking/useParkingPlaceService';
import useZoneService from 'src/services/Parking/useZoneService';
import styled from 'styled-components';
import useIsMounted from '../../hooks/Shared/useIsMounted';

interface DetectionProps {
  onLotDetectionEvent: (event: LotDetectionChangeEventData[]) => void;
  lot: Lot | undefined;
}

export default function Detection(props: DetectionProps) {
  const { onLotDetectionEvent, lot } = props;

  const [offlineGateways, setOfflineGateways] = useState<DetectGateway[]>([]);
  const [parkingPlaces, setParkingPlaces] = useState<ParkingPlace[]>([]);
  const [allParkingPlaces, setAllParkingPlaces] = useState<ParkingPlace[]>([]);
  const [zones, setZones] = useState<Zone[]>([]);
  const [activeParkingPlaceId, setActiveParkingPlaceId] = useState<
    number | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoadingPlaces, setIsLoadingPlaces] = useState(false);
  const [parkingPlacesMeta, setParkingPlacesMeta] = useState<Meta>();
  const { findAllDetectGateways } = useDetectGatewayService();
  const { findAllParkingPlaces, findLimitlessParkingPlaces } =
    useParkingPlaceService();
  const { findAllZones } = useZoneService();
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const socket = useSocket(SocketPath.SOCKETIO);
  const isMounted = useIsMounted();

  const id = useMemo(() => {
    if (!lot) {
      return undefined;
    }

    return lot.id;
  }, [lot]);

  const getImplementationStatus = useMemo(() => {
    if (!lot?.detectionImplementationStatus) return undefined;
    switch (lot.detectionImplementationStatus) {
      case ParkingDetectionImplementationStatus.PENDING:
        return ParkingDetectionImplementationStatus.PENDING;
      case ParkingDetectionImplementationStatus.BLOCKED:
        return ParkingDetectionImplementationStatus.BLOCKED;
      case ParkingDetectionImplementationStatus.NOT_IMPLEMENTED:
        return ParkingDetectionImplementationStatus.NOT_IMPLEMENTED;
      default:
        return undefined;
    }
  }, [lot]);

  useEffect(() => {
    const getAllPlaces = async () => {
      if (!lot) {
        return;
      }

      const response = await findLimitlessParkingPlaces({
        lotId: lot.id,
      });

      if (isMounted()) {
        setAllParkingPlaces(response);
      }
    };

    getAllPlaces();
  }, [isMounted, lot, findLimitlessParkingPlaces]);

  const getParkingPlacesData = useCallback(
    async (page: number) => {
      try {
        if (!lot) {
          return;
        }

        if (isMounted()) {
          setIsLoadingPlaces(true);
        }

        const { data, meta } = await findAllParkingPlaces({
          lotId: lot.id,
          page: page,
          size: PaginationSize.STANDARD,
        });
        if (isMounted()) {
          setParkingPlaces(data);
          if (meta !== undefined) {
            setParkingPlacesMeta(meta);
            setCurrentPage(meta.currentPage);
          }
        }
      } finally {
        isMounted() && setIsLoadingPlaces(false);
      }
    },
    [isMounted, lot, findAllParkingPlaces]
  );

  useEffect(() => {
    const getData = async () => {
      if (!id) {
        return;
      }

      const response = await findAllZones({ lotId: id });

      if (isMounted()) {
        setZones(response.data);
      }
    };

    getData();
  }, [isMounted, id, findAllZones]);

  useEffect(() => {
    getParkingPlacesData(currentPage);
  }, [currentPage, getParkingPlacesData]);

  useEffect(() => {
    const getData = async () => {
      if (!id || lot?.detectionStatus === ParkingDetectionStatus.ONLINE) {
        return;
      }
      const { data } = await findAllDetectGateways({
        lotId: id,
        status: GatewayStatus.OFFLINE,
      });

      if (isMounted()) {
        setOfflineGateways(data);
      }
    };

    getData();
  }, [lot, isMounted, id, findAllDetectGateways]);

  const onPlaceNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setParkingPlaces((oldData) => {
      const index = oldData.findIndex((place) => place.id === id);

      if (index === -1) return oldData;

      oldData[index].name = name;
      return [...oldData];
    });
  }, []);

  const [openParkingPlaceDetailsModal, closeParkingPlaceDetailsModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <ParkingPlaceDetailsModal
          closeParentModal={closeModal}
          id={activeParkingPlaceId}
          onPlaceNameChange={onPlaceNameChange}
          lotDetectionStatus={lot?.detectionStatus}
          zones={zones}
        />
      </GlobalModal>
    ));

  const openModal = useCallback(
    (data?: ParkingPlace) => {
      setActiveParkingPlaceId(data?.id);
      openParkingPlaceDetailsModal();
    },
    [openParkingPlaceDetailsModal]
  );

  const closeModal = useCallback(() => {
    setActiveParkingPlaceId(undefined);
    closeParkingPlaceDetailsModal();
  }, [closeParkingPlaceDetailsModal]);

  useEffect(() => {
    const handleLotDetectionChange = (payload: string) => {
      try {
        const eventPayload: LotDetectionChangeEventPayload =
          JSON.parse(payload);
        const eventData: LotDetectionChangeEvent =
          toLotDetectionChangeEvent(eventPayload);

        const { lots } = eventData;

        onLotDetectionEvent(lots);
        setOfflineGateways([]);
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    const handleParkingPlaceChange = (payload: string) => {
      try {
        const eventPayload: ParkingPlaceChangePayload = JSON.parse(payload);
        const eventData: ParkingPlaceChange =
          toParkingPlaceChange(eventPayload);

        const { places } = eventData;

        setParkingPlaces((oldValue) => {
          places.forEach((place) => {
            const index = oldValue.findIndex((v) => v.id === place.id);
            if (index !== -1) {
              oldValue[index] = { ...oldValue[index], ...place };
            }
          });

          return [...oldValue];
        });
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    const handleParkingGatewayStateChange = (payload: string) => {
      try {
        const eventPayload: DetectGatewayChangePayload = JSON.parse(payload);
        const eventData: DetectGatewayChange =
          toDetectGatewayChange(eventPayload);

        const { gateways } = eventData;

        setOfflineGateways((oldValue) => {
          gateways.forEach((gateway) => {
            const index = oldValue.findIndex((v) => v.id === gateway.id);
            if (index !== -1) {
              oldValue[index] = {
                ...oldValue[index],
                ...gateway,
              };
            }
          });
          return [...oldValue];
        });
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    socket?.on(SocketEvents.LOT_DETECTION_CHANGE, handleLotDetectionChange);
    socket?.on(SocketEvents.PARKING_PLACE_CHANGE, handleParkingPlaceChange);
    socket?.on(
      SocketEvents.PARKING_GATEWAY_STATE_CHANGE,
      handleParkingGatewayStateChange
    );

    return () => {
      socket?.off(SocketEvents.LOT_DETECTION_CHANGE, handleLotDetectionChange);
      socket?.off(SocketEvents.PARKING_PLACE_CHANGE, handleParkingPlaceChange);
      socket?.off(
        SocketEvents.PARKING_GATEWAY_STATE_CHANGE,
        handleParkingGatewayStateChange
      );
    };
  }, [socket, openGlobalFailureModal, setMessage, onLotDetectionEvent]);

  return (
    <React.Fragment>
      {getImplementationStatus ? (
        <NoDetectionMessage implementationStatus={getImplementationStatus} />
      ) : (
        <React.Fragment>
          {offlineGateways.length > 0 ? (
            <ErrorDiv>
              <Error>Offline Parking Gateways:</Error>
              <Error bold>
                {offlineGateways.map((gateway, index) => {
                  const last = index === offlineGateways.length - 1;
                  return gateway.name + (last ? '.' : ', ');
                })}
              </Error>
              <Error>
                Assure they have a stable internet connection and are working
                properly.
              </Error>
            </ErrorDiv>
          ) : null}
          <Title>Cameras</Title>
          <StyledCameraPage>
            <DetectCameraList
              lotDetectionStatus={lot?.detectionStatus}
              lastUpdate={lot?.updatedAt}
              parkingPlaces={allParkingPlaces}
            />
          </StyledCameraPage>
          <ParkingSummary lot={lot} />
          <Title>Places</Title>
          <Fragment>
            <ParkingPlacesTable
              parkingPlaces={parkingPlaces}
              detectionStatus={lot?.detectionStatus}
              isLoading={isLoadingPlaces}
              openEditModal={openModal}
            />
            {parkingPlacesMeta &&
              parkingPlacesMeta.total >=
                PaginationItemDisplay.DISPLAYED_ITEMS && (
                <Pagination
                  meta={parkingPlacesMeta}
                  getData={getParkingPlacesData}
                />
              )}
          </Fragment>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const StyledCameraPage = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 720px);
  min-width: 720px;
  justify-content: center;
  width: 100%;
  margin: 30px 0;

  @media (max-width: 760px) {
    min-width: 100%;
    display: initial;
    grid-template-columns: none;
  }
`;

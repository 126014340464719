import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import Button from 'src/components/Shared/Button';
import DeleteButton from 'src/components/Shared/Buttons/DeleteButton';
import Control from 'src/components/Shared/Control';
import Line from 'src/components/Shared/Line';
import { useGlobalCancelModal } from 'src/components/Shared/Modals/GlobalCancelModal';
import SelectField from 'src/components/Shared/SelectField';
import Spinner from 'src/components/Shared/Spinner';
import TextField from 'src/components/Shared/TextField';
import ParkingDetectionStatus from 'src/constants/Parking/ParkingDetectionStatus';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import type ParkingPlace from 'src/models/Parking/ParkingPlace/ParkingPlace';
import type ParkingPlaceFormValues from 'src/models/Parking/ParkingPlace/ParkingPlaceFormValues';
import type Zone from 'src/models/Parking/Zone/Zone';
import type Option from 'src/models/Shared/Option';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import formatDates from 'src/utils/formatDates';
import { getDetectionStatusColor } from 'src/utils/getDetectionStatusColor';
import DetailsColumn from '../DetailsColumn';
import DetailsData from '../DetailsData';
import DetailsRow from '../DetailsRow';

interface ParkingPlaceUpdateFormProps {
  closeParentModal: () => void;
  lotDetectionStatus: ParkingDetectionStatus | undefined;
  details: ParkingPlace | undefined;
  zones: Zone[];
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  userIsParklioInternal?: boolean;
}
export default function ParkingPlaceUpdateForm(
  props: ParkingPlaceUpdateFormProps
) {
  const {
    details,
    isLoading,
    closeParentModal,
    lotDetectionStatus,
    zones,
    userIsParklioInternal,
    openRemoveModal,
  } = props;
  const [searchZone, setSearchZone] = useState('');
  const { isSubmitting, dirty } = useFormikContext<ParkingPlaceFormValues>();

  const shouldRenderDetails = !isLoading && details;

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const isLotOnline = useMemo(
    () => lotDetectionStatus === ParkingDetectionStatus.ONLINE,
    [lotDetectionStatus]
  );

  const zoneOptions: Option[] = useMemo(
    () =>
      zones
        .filter(({ name }) =>
          name.toLowerCase().includes(searchZone.toLowerCase())
        )
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [zones, searchZone]
  );

  const onRemove = useCallback(() => {
    if (!details?.id || !userIsParklioInternal) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details, userIsParklioInternal]);

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && details && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='NAME'
              placeholder='Name'
              type='text'
              name='name'
              short
              stacked
            />
            <TextField
              label='UUID'
              placeholder='UUID'
              type='text'
              name='uuid'
              stacked
              readonly={!userIsParklioInternal}
            />
            <SelectField
              label='ZONE'
              placeholder='Select Zone'
              name='zone'
              options={zoneOptions}
              short
              onSearch={setSearchZone}
              search={searchZone}
              readonly={!userIsParklioInternal}
              rightAlign={!userIsParklioInternal}
            />
            <DetailsData
              label='Status'
              value={
                isLotOnline
                  ? capitalizeFirstLetter(details.occupancyStatus)
                  : capitalizeFirstLetter(ParkingDetectionStatus.UNKOWN)
              }
              occupancyColor={getDetectionStatusColor(
                details.occupancyStatus,
                isLotOnline
              )}
              formStyle
            />
            <DetailsData
              label='Last updated'
              value={formatDates(details.updatedAt, true)}
              formStyle
            />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        {userIsParklioInternal && <DeleteButton onClick={onRemove} />}
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}

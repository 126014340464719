import React, { forwardRef } from 'react';
import HoverDropdownDimensions from 'src/constants/Shared/HoverDropdownDimensions';
import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';
import DropdownContext from '../../contexts/Shared/DropdownContext';

interface DropdownProps {
  close: () => void;
  children: React.ReactNode;
  id?: string;
  hideInput?: boolean;
  small?: boolean;
  infoMessages?: boolean;
  navigationView?: boolean;
  hideTabOn?: string;
  dateTimeView?: boolean;
  tariffView?: boolean;
  bottomLeftAlign?: boolean;
  parkingPlaceStyle?: { top: number; left: number; visible: boolean };
}

function Dropdown(
  props: DropdownProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined
) {
  const {
    children,
    close,
    id,
    hideInput,
    small,
    infoMessages,
    navigationView,
    hideTabOn,
    dateTimeView,
    tariffView,
    bottomLeftAlign,
    parkingPlaceStyle,
  } = props;

  return (
    <DropdownContext.Provider value={{ close }}>
      <StyledWrapper
        hideInput={hideInput}
        small={small}
        infoMessages={infoMessages}
        navigationView={navigationView}
        hideTabOn={hideTabOn}
        dateTimeView={dateTimeView}
        tariffView={tariffView}
        bottomLeftAlign={bottomLeftAlign}
        id={id}
        parkingPlaceStyle={parkingPlaceStyle}
        ref={ref}
      >
        {children}
      </StyledWrapper>
    </DropdownContext.Provider>
  );
}

export default forwardRef(Dropdown);

const StyledWrapper = styled.div<{
  hideInput?: boolean;
  small?: boolean;
  infoMessages?: boolean;
  navigationView?: boolean;
  hideTabOn?: string;
  dateTimeView?: boolean;
  tariffView?: boolean;
  bottomLeftAlign?: boolean;
  parkingPlaceStyle?: { top: number; left: number; visible: boolean };
}>`
  background-color: ${Color.BACKGROUND_LIGHTEST};
  box-shadow: 0px 0px 30px ${Color.SHADOW_DARK_TRANSPARENT};
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  z-index: 200;
  width: auto;

  ${(props) => {
    const { navigationView } = props;

    if (navigationView) {
      return css`
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        max-width: 200px;
        color: ${Color.TEXT_DARKER};
        background-color: ${Color.BACKGROUND_LIGHTER};
        cursor: pointer;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { hideTabOn } = props;

    if (hideTabOn) {
      return css`
        top: 60px;
        margin-left: 5px;
        @media (max-width: ${hideTabOn}) {
          all: unset;
        }
      `;
    }
    return css``;
  }}

  @media (max-width: 750px) {
    ${(props) => {
      const { tariffView } = props;

      if (tariffView) {
        return css`
          width: auto;
          min-width: 210px;
        `;
      }
      return css``;
    }}
  }

  @media (max-width: 500px) {
    ${(props) => {
      const {
        hideInput,
        infoMessages,
        hideTabOn,
        navigationView,
        dateTimeView,
        tariffView,
      } = props;

      if (hideInput) {
        return css`
          width: 100%;
        `;
      }

      if (infoMessages || tariffView) {
        return css`
          width: auto;
          min-width: auto;
        `;
      }

      if (navigationView) {
        return css`
          width: unset;
          left: -60px;
        `;
      }

      if (hideTabOn) {
        return css`
          width: 90%;
        `;
      }

      if (dateTimeView) {
        return css`
          width: 100%;
        `;
      }

      return css`
        width: 75%;
      `;
    }}
  }

  ${(props) => {
    const { small, infoMessages, navigationView, dateTimeView, tariffView } =
      props;

    if (small) {
      return css`
        width: 50%;
      `;
    }

    if (infoMessages) {
      return css`
        width: auto;
        left: auto;
      `;
    }

    if (navigationView) {
      return css`
        width: auto;
      `;
    }

    if (tariffView) {
      return css`
        min-width: 310px;
      `;
    }

    if (dateTimeView) {
      return css`
        width: 175%;
      `;
    }

    return css`
      width: 100%;
    `;
  }}

  ${(props) => {
    const { parkingPlaceStyle, bottomLeftAlign } = props;

    if (parkingPlaceStyle) {
      const { top, left, visible } = parkingPlaceStyle;
      return css`
        top: ${top}px;
        left: ${left}px;
        position: fixed;
        width: ${HoverDropdownDimensions.WIDTH}px;
        height: ${HoverDropdownDimensions.HEIGHT}px;
        visibility: ${visible ? 'visible' : 'hidden'};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 5px;
        background-color: #fafafa;
        box-shadow: 0px 0px 30px rgb(0 0 0 / 40%);
        padding: 20px;
        display: flex;
        align-items: center;
      `;
    }

    if (bottomLeftAlign) {
      return css`
        bottom: 0;
        left: 0;
      `;
    }

    return css`
      left: 0px;
      top: 40px;
      min-width: 80px;
    `;
  }}
`;
